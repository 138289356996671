import React, { createContext, useState, useEffect } from 'react';
import { deleteChapter as deleteChapterCall, createChapter as createChapterCall, getChapters } from '../db/chapters';

const ChapterContext = createContext();

const ChapterProvider = ({ children }) => {
  const [chapters, setChapters] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedChapter, setSelectedChapter] = useState(null)

  const fetchData = async () => {
    try {
      const chaptersData = await getChapters() // Fetch events data
      setChapters(chaptersData)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching chapters:", error)
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when component mounts
    const intervalId = setInterval(fetchData, 60000) // Fetch data every 60 seconds
    return () => clearInterval(intervalId) // Clear interval on component unmount
  }, [])

  // Function to get a specific chapter by ID
  const getChapterById = id => {
    const chapter = chapters.find(chapter => chapter.link === id)
    setSelectedChapter(chapter)
    return chapter
  }

  const createChapter = async newData => {
    try {
      setLoading(true)
      // Optimistic update
      setChapters([...chapters, newData])
      const createdChapter = await createChapterCall(newData) // Assuming createEventCall is an asynchronous function that creates the event
      console.log(createdChapter)
      setLoading(false)
      fetchData()
    } catch (error) {
      console.error("Error creating chapter:", error)
      setError(error)
      setLoading(false)
      fetchData()
      // Rollback optimistic update if necessary
    }
  }

  const deleteChapter = async chapterID => {
    try {
      setLoading(true)
      // Optimistic update
      setChapters(chapters.filter(chapter => chapter.id !== chapterID))
      await deleteChapterCall(chapterID) // Assuming deleteEventCall is an asynchronous function that deletes the event
      setLoading(false)
      fetchData()
    } catch (error) {
      console.error("Error deleting chapter:", error)
      setError(error)
      setLoading(false)
      fetchData()

      // Rollback optimistic update if necessary
    }
  }

  return (
    <ChapterContext.Provider
      value={{
        chapters,
        selectedChapter,
        getChapterById,
        createChapter,
        deleteChapter,
        loading,
        error,
      }}
    >
      {children}
    </ChapterContext.Provider>
  )
};

export { ChapterContext, ChapterProvider };
