import React, { createContext, useState, useEffect } from 'react';
import { fetchBlogs, createBlog as createBlogCall, updateBlog as updateBlogCall, deleteBlog as deleteBlogCall } from '../db/blogs';

const BlogContext = createContext();

const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBlog, setSelectedBlog] = useState(null);

    const fetchData = async () => {
        try {
            const blogsData = await fetchBlogs(); // Fetch blog data
            setBlogs(blogsData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching blogs:', error);
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data when component mounts
        const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds
        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    // Function to get a specific blog by ID
    const getBlogById = id => {
        const blog = blogs.find(blog => blog._id === id);
        setSelectedBlog(blog);
        return blog;
    };

    const createBlog = async newData => {
        try {
            setLoading(true);
            // Optimistic update
            setBlogs([...blogs, newData]);
            const createdBlog = await createBlogCall(newData); // Assuming createBlogCall is an asynchronous function that creates the blog
            console.log(createdBlog);
            setLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error creating blog:', error);
            setError(error);
            setLoading(false);
            fetchData();
            // Rollback optimistic update if necessary
        }
    };

    const updateBlog = async (blogId, updatedData) => {
        try {
            setLoading(true);
            // Update blog on the server
            await updateBlogCall(blogId, updatedData); // Assuming updateBlogCall is an asynchronous function that updates the blog
            // Update local state with the updated blog
            setBlogs(prevBlogs => prevBlogs.map(blog => {
                if (blog._id === blogId) {
                    return { ...blog, ...updatedData };
                }
                return blog;
            }));
            setLoading(false);
        } catch (error) {
            console.error('Error updating blog:', error);
            setError(error);
            setLoading(false);
            // Rollback optimistic update if necessary
        }
    };

    const deleteBlog = async blogId => {
        try {
            setLoading(true);
            // Optimistic update
            setBlogs(blogs.filter(blog => blog.id !== blogId));
            await deleteBlogCall(blogId); // Assuming deleteBlogCall is an asynchronous function that deletes the blog
            setLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error deleting blog:', error);
            setError(error);
            setLoading(false);
            fetchData();
            // Rollback optimistic update if necessary
        }
    };

    return (
        <BlogContext.Provider
            value={{
                blogs,
                selectedBlog,
                getBlogById,
                createBlog,
                updateBlog,
                deleteBlog,
                loading,
                error,
            }}
        >
            {children}
        </BlogContext.Provider>
    );
};

export { BlogContext, BlogProvider };