import * as React from "react"
import { HelmetProvider } from 'react-helmet-async';
import { EventProvider } from "./src/context/EventContext";
import { ChapterProvider } from "./src/context/ChapterContext";
import { BlogProvider } from "./src/context/BlogContext";
import "./styles.css"

export const wrapRootElement = ({ element }) => {
  return <EventProvider>
    <ChapterProvider>
      <BlogProvider>
      <HelmetProvider>
        {element}
      </HelmetProvider>
      </BlogProvider>
    </ChapterProvider>
  </EventProvider>
};

export const onRouteUpdate = ({ location }) => {
  const allowedRoutes = [
    '',
    '/thank-you',
    '/guidelines',
    '/terms',
    '/privacy',
    '/404',
    '/volunteer',
    '/join-the-team',
    '/meetup-tour',
    '/event',
    '/events',
    '/admin',
    '/login',
    '/logout',
  ]
  const pathnameWithoutSlash = location?.pathname.replace(/\/$/, '');
  // Check if the user is accessing a restricted route
  // if (![...allowedRoutes].includes(pathnameWithoutSlash)) {
  //   // Redirect to the custom 404 page
  //   window.location.replace('/404');
  // }

    // Check if the user is accessing a restricted route
    const isAllowedRoute = allowedRoutes.includes(pathnameWithoutSlash) || pathnameWithoutSlash.startsWith('/event/');

    // if (!isAllowedRoute) {
    //   // Redirect to the custom 404 page
    //   window.location.replace('/404');
    // }
};