import axios from "axios"

export async function FetchGET(url) {
  const { data } = await axios(`${process.env.GATSBY_API_URL}${url}`)
  return data
  return fetch(`${process.env.GATSBY_API_URL}${url}`)
}

export function FetchPOST(url, data, customUrl = false) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }
  const formattedUrl = customUrl ? url : process.env.GATSBY_API_URL + url
  return fetch(`${formattedUrl}`, requestOptions)
}

export function FetchPATCH(url, data) {
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }
  return fetch(`${process.env.GATSBY_API_URL}${url}`, requestOptions)
}

export function FetchDELETE(url, id) {
  const requestOptions = {
    method: "DELETE",
  }
  return fetch(`${process.env.GATSBY_API_URL}${url}/${id}`, requestOptions)
}

export default { FetchGET, FetchPOST, FetchDELETE }
