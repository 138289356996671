import { FetchDELETE, FetchGET, FetchPATCH, FetchPOST } from "./fetch"

export const getEvents = async () => {
    const data = await FetchGET(`/events`)
    return data
}

export const getEvent = async (id) => {
    const data = await FetchGET(`/events/${id}`)
    return data
}

export const getEventRegistrations = async (id) => {
    const data = await FetchGET(`/events/${id}/registrations`)
    return data
}

export const createEvent = async (passedData) => {
    const data = await FetchPOST(`/events`, passedData)
    return data
}

export const updateEvent = async (id,eventData) => {
    const data = await FetchPATCH(`/events/${id}`, eventData)
    return data
}

export const deleteEvent = async (id) => {
    const data = await FetchDELETE('/events', id)
}