import { FetchDELETE, FetchGET, FetchPATCH, FetchPOST } from "./fetch"

export const fetchBlogs = async () => {
    const data = await FetchGET(`/blogs`)
    return data
}

export const getBlog = async (id) => {
    const data = await FetchGET(`/blogs/${id}`)
    return data
}

export const createBlog = async (passedData) => {
    const data = await FetchPOST(`/blogs`, passedData)
    console.log('submitting')
    return data
}

export const updateBlog = async (id,eventData) => {
    const data = await FetchPATCH(`/blogs/${id}`, eventData)
    return data
}

export const deleteBlog = async (id) => {
    const data = await FetchDELETE('/blogs', id)
}