import React, { createContext, useState, useEffect } from 'react';
import { deleteEvent as deleteEventCall, createEvent as createEventCall, getEvents, updateEvent as updateEventCall, getEventRegistrations } from '../db/events';
import moment from "moment"

const EventContext = createContext()

const EventProvider = ({ children }) => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [registrationsLoading, setRegistrationsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventRegistrations, setSelectedEventRegistrations] = useState(null)
  const [chapterEvents, setChapterEvents] = useState(null)

  const fetchData = async () => {
    try {
      const eventsData = await getEvents() // Fetch events data
      setEvents(eventsData)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching events:", error)
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when component mounts
    const intervalId = setInterval(fetchData, 60000) // Fetch data every 60 seconds
    return () => clearInterval(intervalId) // Clear interval on component unmount
  }, [])

  // Function to get a specific chapter by ID
  const getEventById = id => {
    const event = events.find(event => event._id === id)
    setSelectedEvent(event)
    return event
  }

  const getEventByUrl = async (url) => {
    const event = events.find(event => event.url === url);
    setSelectedEvent(event);
    return event;
  };
  
  const getEventRegistrationsById = async (id) => {
    try {
      setRegistrationsLoading(true);
      const data = await getEventRegistrations(id);
      setSelectedEventRegistrations(data);
      setRegistrationsLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching event registrations:", error);
      setError(error);
      setRegistrationsLoading(false);
    }
  };

  // Function to fetch events by chapter ID
  const getEventsByChapterId = async chapterId => {
    const filteredEvents = [...events].filter(
      event =>
        event.chapter === chapterId && moment(event.eventStartDate) > moment()
    )
    setChapterEvents(filteredEvents)
    return filteredEvents
  }

  const createEvent = async newEvent => {
    try {
      setLoading(true)
      // Optimistic update
      setEvents([...events, newEvent])
      const createdEvent = await createEventCall(newEvent) // Assuming createEventCall is an asynchronous function that creates the event
      setLoading(false)
      fetchData()
    } catch (error) {
      console.error("Error creating event:", error)
      setError(error)
      setLoading(false)
      fetchData()
      // Rollback optimistic update if necessary
    }
  }

  const updateEvent = async (id, eventData) => {
    try {
      setLoading(true)
      // Assuming eventData includes the updated event object with an ID field
      const updatedEvent = await updateEventCall(id, eventData) // Corrected typo in updateEventCall
      // Update events array with the updated event
      setEvents(prevEvents =>
        prevEvents.map(event => (event.id === id ? updatedEvent : event))
      )
      setLoading(false)
      fetchData()
    } catch (error) {
      console.error("Error updating event:", error)
      setError(error)
      setLoading(false)
      fetchData()
      // Rollback optimistic update if necessary
    }
  }
  const deleteEvent = async eventId => {
    try {
      setLoading(true)
      // Optimistic update
      setEvents(events.filter(event => event.id !== eventId))
      await deleteEventCall(eventId) // Assuming deleteEventCall is an asynchronous function that deletes the event
      setLoading(false)
      fetchData()
    } catch (error) {
      console.error("Error deleting event:", error)
      setError(error)
      setLoading(false)
      fetchData()

      // Rollback optimistic update if necessary
    }
  }

  return (
    <EventContext.Provider
      value={{
        events,
        selectedEvent,
        selectedEventRegistrations,
        chapterEvents,
        getEventById,
        getEventById,
        getEventByUrl,
        getEventsByChapterId,
        getEventRegistrationsById,
        createEvent,
        updateEvent,
        deleteEvent,
        loading,
        registrationsLoading,
        error,
      }}
    >
      {children}
    </EventContext.Provider>
  )
}

export { EventContext, EventProvider };
