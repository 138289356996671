import { FetchDELETE, FetchGET, FetchPATCH, FetchPOST } from "./fetch"

export const getChapters = async () => {
    const data = await FetchGET(`/chapters`)
    return data
}

export const getChapter = async (id) => {
    const data = await FetchGET(`/chapters/${id}`)
    return data
}

export const createChapter = async (passedData) => {
    const data = await FetchPOST(`/chapters`, passedData)
    return data
}

export const updateChapter = async (id,eventData) => {
    const data = await FetchPATCH(`/chapters/${id}`, eventData)
    return data
}

export const deleteChapter = async (id) => {
    const data = await FetchDELETE('/chapters', id)
}